import _trim from "lodash-es/trim";
import React from 'react';
import { Content } from '@/common/components/content';
import { Layout } from '@/common/components/layout';
import { ScrollRestore } from '@/common/components/scroll-restore';
import { SEO } from '@/common/components/seo';
import { useAppInfo, useUri } from '@/common/hooks';
import { mapMaybe } from '@/common/utils/map-maybe';
import { jsx as ___EmotionJSX } from "@emotion/core";
var query = "1982205008";

var Page = function Page(_ref) {
  var _data$cms, _data$cms$page, _data$cms$page$data, _attributes$seo, _data$cms$page2, _data$cms$page2$data, _seo$social$facebook$, _seo$social, _seo$social$facebook, _seo$social$twitter$t, _seo$social2, _seo$social2$twitter, _data$cms$page3, _data$cms$page3$data, _seo$social3, _seo$social3$facebook, _seo$social3$facebook2, _seo$social3$facebook3, _seo$social3$facebook4, _seo$social3$facebook5, _seo$social3$facebook6, _seo$social4, _seo$social4$twitter, _seo$social4$twitter$, _seo$social4$twitter$2, _seo$social4$twitter$3, _seo$social4$twitter$4, _seo$social4$twitter$5, _ref2, _seo$social$facebook$2, _seo$social5, _seo$social5$facebook, _ref3, _seo$social$twitter$d, _seo$social6, _seo$social6$twitter, _data$cms2, _data$cms2$page, _data$cms2$page$data, _data$cms$page4, _data$cms$page4$data;

  var data = _ref.data;

  var _useAppInfo = useAppInfo(),
      defaultMetaDescription = _useAppInfo.defaultMetaDescription;

  var _useUri = useUri(),
      uriHelper = _useUri.uriHelper;

  var attributes = data === null || data === void 0 ? void 0 : (_data$cms = data.cms) === null || _data$cms === void 0 ? void 0 : (_data$cms$page = _data$cms.page) === null || _data$cms$page === void 0 ? void 0 : (_data$cms$page$data = _data$cms$page.data) === null || _data$cms$page$data === void 0 ? void 0 : _data$cms$page$data.attributes;
  var pageContent = attributes === null || attributes === void 0 ? void 0 : attributes.content;
  var pageTitle = (attributes === null || attributes === void 0 ? void 0 : (_attributes$seo = attributes.seo) === null || _attributes$seo === void 0 ? void 0 : _attributes$seo.title) || (attributes === null || attributes === void 0 ? void 0 : attributes.title);
  var seo = (_data$cms$page2 = data.cms.page) === null || _data$cms$page2 === void 0 ? void 0 : (_data$cms$page2$data = _data$cms$page2.data) === null || _data$cms$page2$data === void 0 ? void 0 : _data$cms$page2$data.attributes.seo;

  var ogTitle = _trim((_seo$social$facebook$ = seo === null || seo === void 0 ? void 0 : (_seo$social = seo.social) === null || _seo$social === void 0 ? void 0 : (_seo$social$facebook = _seo$social.facebook) === null || _seo$social$facebook === void 0 ? void 0 : _seo$social$facebook.title) !== null && _seo$social$facebook$ !== void 0 ? _seo$social$facebook$ : pageTitle);

  var twitterTitle = _trim((_seo$social$twitter$t = seo === null || seo === void 0 ? void 0 : (_seo$social2 = seo.social) === null || _seo$social2 === void 0 ? void 0 : (_seo$social2$twitter = _seo$social2.twitter) === null || _seo$social2$twitter === void 0 ? void 0 : _seo$social2$twitter.title) !== null && _seo$social$twitter$t !== void 0 ? _seo$social$twitter$t : pageTitle);

  var meta = [{
    property: 'og:url',
    content: uriHelper.ensureAbsoluteUrl(uriHelper.getCmsPageUrl((_data$cms$page3 = data.cms.page) === null || _data$cms$page3 === void 0 ? void 0 : (_data$cms$page3$data = _data$cms$page3.data) === null || _data$cms$page3$data === void 0 ? void 0 : _data$cms$page3$data.attributes.uri))
  }, {
    property: 'og:image',
    content: CONFIG.appUrl + ((seo === null || seo === void 0 ? void 0 : (_seo$social3 = seo.social) === null || _seo$social3 === void 0 ? void 0 : (_seo$social3$facebook = _seo$social3.facebook) === null || _seo$social3$facebook === void 0 ? void 0 : (_seo$social3$facebook2 = _seo$social3$facebook.image) === null || _seo$social3$facebook2 === void 0 ? void 0 : (_seo$social3$facebook3 = _seo$social3$facebook2.asset) === null || _seo$social3$facebook3 === void 0 ? void 0 : (_seo$social3$facebook4 = _seo$social3$facebook3.localImageFile) === null || _seo$social3$facebook4 === void 0 ? void 0 : (_seo$social3$facebook5 = _seo$social3$facebook4.childImageSharp) === null || _seo$social3$facebook5 === void 0 ? void 0 : (_seo$social3$facebook6 = _seo$social3$facebook5.resize) === null || _seo$social3$facebook6 === void 0 ? void 0 : _seo$social3$facebook6.src) || '')
  }, {
    property: 'twitter:image',
    content: CONFIG.appUrl + ((seo === null || seo === void 0 ? void 0 : (_seo$social4 = seo.social) === null || _seo$social4 === void 0 ? void 0 : (_seo$social4$twitter = _seo$social4.twitter) === null || _seo$social4$twitter === void 0 ? void 0 : (_seo$social4$twitter$ = _seo$social4$twitter.image) === null || _seo$social4$twitter$ === void 0 ? void 0 : (_seo$social4$twitter$2 = _seo$social4$twitter$.asset) === null || _seo$social4$twitter$2 === void 0 ? void 0 : (_seo$social4$twitter$3 = _seo$social4$twitter$2.localImageFile) === null || _seo$social4$twitter$3 === void 0 ? void 0 : (_seo$social4$twitter$4 = _seo$social4$twitter$3.childImageSharp) === null || _seo$social4$twitter$4 === void 0 ? void 0 : (_seo$social4$twitter$5 = _seo$social4$twitter$4.resize) === null || _seo$social4$twitter$5 === void 0 ? void 0 : _seo$social4$twitter$5.src) || '')
  }, {
    property: 'og:title',
    content: ogTitle
  }, {
    property: 'og:description',
    content: (_ref2 = (_seo$social$facebook$2 = seo === null || seo === void 0 ? void 0 : (_seo$social5 = seo.social) === null || _seo$social5 === void 0 ? void 0 : (_seo$social5$facebook = _seo$social5.facebook) === null || _seo$social5$facebook === void 0 ? void 0 : _seo$social5$facebook.description) !== null && _seo$social$facebook$2 !== void 0 ? _seo$social$facebook$2 : seo === null || seo === void 0 ? void 0 : seo.description) !== null && _ref2 !== void 0 ? _ref2 : defaultMetaDescription
  }, {
    name: 'twitter:title',
    content: twitterTitle
  }, {
    name: 'twitter:description',
    content: (_ref3 = (_seo$social$twitter$d = seo === null || seo === void 0 ? void 0 : (_seo$social6 = seo.social) === null || _seo$social6 === void 0 ? void 0 : (_seo$social6$twitter = _seo$social6.twitter) === null || _seo$social6$twitter === void 0 ? void 0 : _seo$social6$twitter.description) !== null && _seo$social$twitter$d !== void 0 ? _seo$social$twitter$d : seo === null || seo === void 0 ? void 0 : seo.description) !== null && _ref3 !== void 0 ? _ref3 : defaultMetaDescription
  }];
  return ___EmotionJSX(Layout, {
    key: data === null || data === void 0 ? void 0 : (_data$cms2 = data.cms) === null || _data$cms2 === void 0 ? void 0 : (_data$cms2$page = _data$cms2.page) === null || _data$cms2$page === void 0 ? void 0 : (_data$cms2$page$data = _data$cms2$page.data) === null || _data$cms2$page$data === void 0 ? void 0 : _data$cms2$page$data.id,
    smallContentSpacing: true
  }, ___EmotionJSX(SEO, {
    title: pageTitle !== null && pageTitle !== void 0 ? pageTitle : '',
    meta: meta,
    description: (seo === null || seo === void 0 ? void 0 : seo.description) || defaultMetaDescription
  }), ___EmotionJSX(ScrollRestore, {
    uri: uriHelper.getCmsPageUrl((_data$cms$page4 = data.cms.page) === null || _data$cms$page4 === void 0 ? void 0 : (_data$cms$page4$data = _data$cms$page4.data) === null || _data$cms$page4$data === void 0 ? void 0 : _data$cms$page4$data.attributes.uri)
  }), pageContent ? ___EmotionJSX(Content, {
    contentBlocks: mapMaybe(pageContent)
  }) : null);
};

Page.displayName = "Page";
export { Page as default, query };